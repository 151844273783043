<template>
    <div class="data">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="configurator-flex data__left">
                    <div class="data__illustration">
                        <img class="data__illustration-img" alt="illustration"
                             src="/src/configurator/img/data_illustration.png" v-if="optionKey() !== 'plafond_kruipkelder' && optionKey() !== 'vloer_kelder'" />
                        <img class="data__illustration-img" alt="illustration"
                             src="/src/configurator/img/data_illustration_basement.png" v-if="optionKey() === 'plafond_kruipkelder' || optionKey() === 'vloer_kelder'"/>
                    </div>
                    <div class="data__illustration-title">
                        {{ textTitle }}
                    </div>
                    <div class="data__illustration-text" v-html="textText">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="configurator-flex configurator-flex--color data__right">
                    <h1 class="type__right-title form-title form-title--light">
                        <span class="stepnumber stepnumber--light">{{ this.activeStepIndex + 1 }}</span>
                        {{ optionString() }}
                    </h1>
                    <error-message :errors="this.errors"></error-message>

                    <div class="data__right-section">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Welke ondergrond is er
                            aanwezig?</h2>
                        <div class="row">
                            <div class="col-6">
                                <input type="checkbox" value="beton" :id="'beton'+index"
                                       v-model="store.ground[optionKey()].grond">
                                <label class="configurator__label" :for="'beton'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Betonvloer</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="checkbox" value="hout" :id="'hout'+index"
                                       v-model="store.ground[optionKey()].hout">
                                <label class="configurator__label" :for="'hout'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Houtvloer</span>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <input type="checkbox" value="zand" :id="'zand'+index"
                                       v-model="store.ground[optionKey()].zand">
                                <label class="configurator__label" :for="'zand'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Zand</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="checkbox" value="andere" :id="'andere'+index"
                                       v-model="store.ground[optionKey()].andere">
                                <label class="configurator__label" :for="'andere'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Andere</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-section">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Wil je vloerisolatie en/of
                            chape?</h2>
                        <input type="checkbox" id="isolatie"
                               v-model="store.isolation[optionKey()]">
                        <label class="configurator__label pe-2" for="isolatie">
                            <span class="checkbox-check checkbox-check--light"></span>
                            <span class="checkbox-label checkbox-label checkbox-label--light">Ja, ik wil vloerisolatie</span>
                        </label>
                        <br><br>

                        <input type="radio" value="chape" id="chape_ja"
                               v-model="store.chape[optionKey()]">
                        <label class="configurator__label pe-2" for="chape_ja">
                            <span class="checkbox-check checkbox-check--light"></span>
                            <span class="checkbox-label checkbox-label checkbox-label--light">Ja, ik wil chape</span>
                        </label>
                        <input type="radio" value="geen" id="chape_nee"
                               v-model="store.chape[optionKey()]">
                        <label class="configurator__label pe-2" for="chape_nee">
                            <span class="checkbox-check checkbox-check--light"></span>
                            <span class="checkbox-label checkbox-label checkbox-label--light">Nee, ik wil geen chape</span>
                        </label>
                    </div>

                    <div class="data__right-groupedsection">
                        <div class="data__right-section">
                            <h2 class="data__right-section-title form-subtitle form-subtitle--light"
                                v-if="store.isolation[optionKey()] || (!store.isolation[optionKey()] && !store.chape[optionKey()]) || (store.chape[optionKey()] === 'geen')">
                                Isolatie dikte
                            </h2>
                            <h2 class="data__right-section-title form-subtitle form-subtitle--light"
                                v-if="!store.isolation[optionKey()] && (store.chape[optionKey()] === 'chape' || store.chape[optionKey()] === 'polybeton')">
                                Chape dikte
                            </h2>
                            <input type="number" min="0" id="isolatiedikte" class="data__right-section-input"
                                   v-model="store.thickness[optionKey()]"/>
                            <label class="configurator__label p-2" for="isolatiedikte">cm</label>
                        </div>
                        <div class="data__right-section" v-if="optionKey() !== 'plafond_kruipkelder'">
                            <h2 class="data__right-section-title form-subtitle form-subtitle--light">Vloeropbouw hoogte</h2>
                            <input type="number" min="0" id="vloeropbouw" class="data__right-section-input"
                                   v-model="store.height[optionKey()]"/>
                            <label class="configurator__label p-2" for="vloeropbouw">cm (optioneel)</label>
                        </div>
                    </div>

                    <div class="data__right-section">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Je vloeroppervlakte
                            bedraagt</h2>
                        <input type="number" min="0" id="oppervlakte" class="data__right-section-input"
                               v-model="store.floorsize[optionKey()]"/>
                        <label class="configurator__label p-2" for="oppervlakte">m<sup>2</sup></label>
                    </div>

                    <div class="data__right-section">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Is er vloerverwarming
                            aanwezig?</h2>
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="vloerverwarming_ja"
                                       v-model="store.floorheating[optionKey()]">
                                <label class="configurator__label pe-2" for="vloerverwarming_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Ja</span>
                                </label>
                                <input type="radio" value="0" id="vloerverwarming_nee"
                                       v-model="store.floorheating[optionKey()]">
                                <label class="configurator__label p-2" for="vloerverwarming_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Nee</span>
                                </label>
                            </div>
                            <div class="data__right-section-inputs__right">
                                <div v-if="store.floorheating[optionKey()] == 1">
                                    <label class="configurator__label configurator__label--light p-2" for="oppervlakte_vloerverwarming">Over
                                        welke oppervlakte? </label>
                                    <input type="number" min="0" id="oppervlakte_vloerverwarming" class="data__right-section-input"
                                           v-model="store.floorheatingsize[optionKey()]"/>
                                    <label class="configurator__label configurator__label--light p-2"
                                           for="oppervlakte_vloerverwarming">m<sup>2</sup></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data__right-section">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Wil je hellingschape?</h2>
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="helling_ja"
                                       v-model="store.slope[optionKey()]">
                                <label class="configurator__label pe-2" for="helling_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Ja</span>
                                </label>
                                <input type="radio" value="0" id="helling_nee"
                                       v-model="store.slope[optionKey()]">
                                <label class="configurator__label p-2" for="helling_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Nee</span>
                                </label>
                            </div>
                            <div class="data__right-section-inputs__right">
                                <div v-if="store.slope[optionKey()] == 1">
                                    <label class="configurator__label configurator__label--light p-2" for="oppervlakte_helling">Over
                                        welke oppervlakte? </label>
                                    <input type="number" min="0" id="oppervlakte_helling" class="data__right-section-input"
                                           v-model="store.slopesize[optionKey()]"/>
                                    <label class="configurator__label configurator__label--light p-2"
                                           for="oppervlakte_helling">m<sup>2</sup></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <a href="#"
                           @click.stop.prevent="previousStep"
                           class="button button--white-1">
                            Vorige
                        </a>
                        <a href="#"
                           @click.stop.prevent="nextStep"
                           class="button button--white-1">
                            Volgende
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepMixin from "@/mixins/StepMixin";
import {store} from '@/store/dataStore';
import ErrorMessage from "@/components/layout/Formerrors";

export default {
    name: 'DataStep',
    mixins: [StepMixin],
    components: {
        ErrorMessage
    },
    data() {
        return {
            errors: [],
            store,
            textTitle : '',
            textText : '',
            options: {
                dotSize: 22,
                height: 10,
                min: 3,
                max: 30,
                interval: 1,
                tooltip: false,
            }
        }
    },
    props: [
        'activeStep',
        'activeStepIndex'
    ],
    methods: {
        optionKey() {
            return this.store.options[this.activeStepIndex - 1]
        },
        optionString() {
            var option = this.store.suboptions[this.store.type].find(option => option.key === this.store.options[this.activeStepIndex - 1]);
            return option.value.toLowerCase();
        },
        nextStep() {
            this.validateData();

            if (!this.errors.length) {
                this.$emit('nextStep');
            }
        },
        goToStep(index) {
            this.$emit('goToStep', index);
        },
        validateData() {
            this.errors = [];


            const hasChecks = this.store.ground[this.optionKey()].grond ||
                              this.store.ground[this.optionKey()].hout ||
                              this.store.ground[this.optionKey()].zand ||
                              this.store.ground[this.optionKey()].andere;

            if (!hasChecks) {
                this.errors.push('Gelieve een ondergrond aan te duiden.')
            }

            if (!this.store.ground[this.optionKey()]) {
                this.errors.push('Gelieve een ondergrond aan te duiden.')
            }

            if (!this.store.thickness[this.optionKey()]) {
                this.errors.push('Gelieve een isolatie dikte in te geven.')
            }

            if (!this.store.floorsize[this.optionKey()]) {
                this.errors.push('Gelieve een vloeroppervlakte in te geven.')
            }

            if ((this.store.floorheating[this.optionKey()] != 0) && !this.store.floorheatingsize[this.optionKey()]) {
                this.errors.push('Gelieve de oppervlakte van vloerverwarming in te geven.')
            }

            if ((this.store.slope[this.optionKey()] != 0) && !this.store.slopesize[this.optionKey()]) {
                this.errors.push('Gelieve de oppervlakte van de hellingschape in te geven.')
            }
        }
    },
    async mounted() {
        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();

        const result = await this.$get(baseHref + '/home/ajax/getText/config_stap2', '', this.abortController.signal);
        this.abortController = null;

        if (result) {
            this.textTitle = result.title;
            this.textText = result.text;
        }

        const header = document.getElementById('config__header');
        header.className = 'configurator__header';
    },
    created() {
        this.store.options.forEach((value) => {
            if (!this.store.ground.hasOwnProperty(value)) {
                this.store.ground[value] = {grond: true, hout: false, zand: false, andere: false};
            }
        });

        this.store.options.forEach((value) => {
            if (!this.store.height.hasOwnProperty(value)) {
                this.store.height[value] = 0;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.thickness.hasOwnProperty(value)) {
                this.store.thickness[value] = 0;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.isolation.hasOwnProperty(value)) {
                this.store.isolation[value] = true;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.chape.hasOwnProperty(value)) {
                this.store.chape[value] = 'geen';
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.floorsize.hasOwnProperty(value)) {
                this.store.floorsize[value] = 0;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.floorheating.hasOwnProperty(value)) {
                this.store.floorheating[value] = 0;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.floorheatingsize.hasOwnProperty(value)) {
                this.store.floorheatingsize[value] = 0;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.slope.hasOwnProperty(value)) {
                this.store.slope[value] = 0;
            }
        });
        this.store.options.forEach((value) => {
            if (!this.store.slopesize.hasOwnProperty(value)) {
                this.store.slopesize[value] = 0;
            }
        });
    },
}
</script>
