<template>
    <div class="type">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="configurator-fixed configurator-fixed--color type__left">
                    <h1 class="form-title form-title--light">
                        {{ title }}
                    </h1>
                    <div class="type__left__top-content" v-html="text">
                    </div>
                    <div class="type__left__bottom-content">
                        <div class="type__left__bottom-content-title">{{ question_title }}</div>
                        <div class="type__left__bottom-content-info" v-html="question_text">
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="configurator-flex type__right">
                    <div class="type__right-content">
                        <h1 class="type__right-title form-title">
                            <span class="stepnumber">1</span>
                            Ga je bouwen of renoveren?
                        </h1>
                        <div class="type__right-options">
                            <div class="type__right-option" v-for="type in store.types" :key="type.key">
                                <input type="radio"
                                       :id="type.key"
                                       :value="type.key"
                                       :name="type.key"
                                       v-model="store.type"
                                       @click="() => {store.options = []}"
                                >
                                <label class="type__right-option__label" :for="type.key">
                                    <i :class="'type__right-option__icon ' + type.icon"></i>
                                    {{ type.value }}
                                </label>
                            </div>
                        </div>
                        <div class="type__right-suboptions">
                            <h2 class="type__right-suboptions-title form-title">Ik wil laten isoleren</h2>
                            <ul class="type__right-suboptions-list">
                                <li class="type__right-suboptions-list__option"
                                    v-for="suboption in store.suboptions[store.type]"
                                    :key="suboption.key"
                                >
                                    <input type="checkbox" :value="suboption.key" :id="suboption.key"
                                           v-model="store.options">
                                    <label class="configurator__label" :for="suboption.key">
                                        <span class="checkbox-check"></span>
                                        <span class="checkbox-label">{{ suboption.value }}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <button href="#"
                                @click.stop.prevent="nextStep"
                                class="type__right-button"
                                v-if="store.options.length > 0">
                            Bereken je voorstel in {{ stepAmount }} korte stappen <i
                                class="type__right-button-icon icon-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepMixin from "@/mixins/StepMixin";
import {store} from '@/store/dataStore';

export default {
    name: 'TypeStep',
    mixins: [StepMixin],
    data() {
        return {
            title: '',
            text: '',
            question_title: '',
            question_text: '',
            store,
            stepAmount: 0,
        }
    },
    methods: {
        updateNextSteps() {
            this.store.steps = [
                'TypeStep',
                'InfoStep',
                'ConfirmStep',
            ];
            this.stepAmount = 2 + this.store.options.length;

            for (let i = 0; i < this.store.options.length; i++) {
                this.store.steps.splice(i + 1, 0, 'DataStep');
            }
        }
    },
    watch: {
        // whenever question changes, this function will run
        selectedOptions: function () {
            this.updateNextSteps();

            // var isAdded = this.store.suboptions.cellulose.some((option) => {
            //     return option.key === "dampscherm";
            // })
            // if ((newoptions.includes('tussenvloeren') || newoptions.includes('dakisolatie')) &&
            //      !isAdded) {
            //     this.store.suboptions.cellulose.push({ key: 'dampscherm', value: '+ Intelligent dampscherm' })
            // }
            // if (!(newoptions.includes('tussenvloeren') || newoptions.includes('dakisolatie')) &&
            //         isAdded) {
            //     this.store.suboptions.cellulose.splice(
            //             this.store.suboptions.cellulose.indexOf({ key: 'dampscherm', value: '+ Intelligent dampscherm' }),
            //             1
            //     );
            //     this.store.options.splice(this.store.options.indexOf('dampscherm'), 1);
            // }
        }
    },
    computed: {
        selectedOptions() {
            return this.store.options;
        }
    },
    async mounted() {
        if (this.store.type === '') this.store.type = 'nieuwbouw';
        this.stepAmount = 2 + this.store.options.length;

        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();

        const result = await this.$get(baseHref + '/home/ajax/getText/config_stap1', '', this.abortController.signal);
        const resultQuestion = await this.$get(baseHref + '/home/ajax/getText/config_question', '', this.abortController.signal);
        this.abortController = null;

        if (result) {
            this.title = result.title;
            this.text = result.text;
        }
        if (resultQuestion) {
            this.question_title = resultQuestion.title;
            this.question_text = resultQuestion.text;
        }

        const header = document.getElementById('config__header');
        header.className = 'configurator__header configurator__header--reverse';
    }
}
</script>
