<template>
    <div class="confirm">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="configurator-fixed configurator-fixed--color confirm__left">
                    <h1 class="confirm__title form-title form-title--light">
                        Bedankt voor uw aanvraag!
                    </h1>
                    <div class="confirm__img-wrapper">
                        <img class="confirm__img" alt="Verbiest"
                             src="/src/configurator/img/verbiest.png"/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="configurator-fixed configurator-fixed--right confirm__right">
                    <div class="confirm__right-content">
                        <p>Beste {{ store.info.firstname }} {{ store.info.surname }},</p>
                        <div v-html="text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepMixin from "@/mixins/StepMixin";
import {store} from '@/store/dataStore';

export default {
    name: 'ConfirmStep',
    mixins: [StepMixin],
    data() {
        return {
            store,
            text: '',
        }
    },
    async mounted() {
        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();

        const result = await this.$get(baseHref + '/home/ajax/getText/config_confirm', '', this.abortController.signal);
        this.abortController = null;

        if (result) {
            this.text = result.text;
        }

        const header = document.getElementById('config__header');
        header.className = 'configurator__header configurator__header--reverse';
    },
}

</script>
